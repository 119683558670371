import { defineStore } from 'pinia';

export const useAdminStore = defineStore('Admin', {
  state: () => ({
    selectedTab: 'TabReports',
    lastScrollPosition: 0,
  }),
  getters: {

  },
  actions: {
    changeTab(tabName) {
      if (this.selectedTab === 'TabReports' && tabName !== 'TabReports') {
        this.lastScrollPosition = window.scrollY;
      }
      this.selectedTab = tabName;
    },
  },
});